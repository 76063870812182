<template>
  <div class="container--fluid">
    <div class="background">
      <span>404</span>
    </div>
    <div class="logo pl-8 pt-8" style="width: 100px; height: 100px">
      <img src="../assets/logo_violet.png" alt="" class="logo" style="width: 80px; height: 80px">
    </div>
    <div style="align-content: center" class="mr-0 content">
      <div class=" l-invalid text-center ">
        <div class="line"></div>
        <p class="font-weight-bold title-app">
          OOPS !
        </p>
        <p class="text-p">
          {{ $t('not_found.title') }}
        </p>
        <p class="e">
          {{$t('not_found.explication')}}
        </p>
        <v-btn outlined class="primary" color="white" @click="$router.push({name: 'Login'})">
          {{$t('not_found.btn')}}
        </v-btn>
      </div>



    </div>
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style lang="scss" scoped>

.background {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;

  text-align: center;
}

.background::before {
  display: inline-block;
  height: 100%;
  content: '';
}

span {
  font-family: Poppins, serif !important;
  font-size: 50vw;
  color: #FAF6F8;
  vertical-align: baseline;
  padding-bottom: 10px;
}

.text-p {
  color: #790346;
  font-size: 3rem;
}

.line{
  margin: auto;
  text-align: center;
  width: 50px;

  border-bottom: 5px solid #790346;

}

.l-invalid {
  align-self: center;
}

.title-app {
  padding-top: 20px;
  font-size: 40px;
}

.e {
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.content {
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

</style>
